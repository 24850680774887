<template>
	<div class="container-fluid">
		<div class="row">
			<div class="col-lg-12">
				<div class="d-flex flex-wrap flex-wrap align-items-center justify-content-between mb-4">
                    <div>
                        <h4 class="mb-3">{{title}}</h4>
                        <p class="mb-0">{{desc1}}<br> {{desc2}}<br>{{desc3}}</p>
                        <p v-b-modal.modal-3 variant="link" class="mb-0"><u>판매내역 수집 방법</u></p>
                            <b-modal id="modal-3" size="xl" title="판매내역 수집 방법" ok-title="Save Changes" cancel-title="Close">
                                <p><a href="https://chrome.google.com/webstore/detail/couplus/bogokmehpkdjafnlhjhinfgmeljdgmgh?hl=ko&authuser=0" target="_blank"><u>구글웹스토어</u></a>에 접속해서 "COUPLUS"를 다운받은 후 <b style="color:red"><u>"Supplier"에 로그인한 상태에서</u></b> 판매내역 수집 버튼을 클릭해주세요.<br> 판매내역 수집 예상시간이 끝날때까지 창을 닫거나 조정하시면 안됩니다.(SKU당 약0.2초소요)</p>
                                <img  style="max-width:100%;" :src="require('../../../assets/images/small/extension_sales.png')">
                            </b-modal>
                    </div>
                </div>
			</div>
			<card class="col-lg-12">
				<div class="p-3">
					<form  @submit.prevent="submit" >
						<div class="row">
							<!-- <div class="col-md-4">
								<div class="form-group">
									<label class="ml-1">SKU ID</label>
									<input type="text" class="form-control" placeholder="SKU ID" v-model="SKUID" style="height:60px">
								</div>
							</div> -->
							<!-- <div class="col-md-4">
								<div class="form-group">
									<label class="ml-1">조회날짜(시작일)</label>
									<b-form-datepicker id="example-datepicker1" v-model="startdate" class="mb-2"></b-form-datepicker>
								</div>
							</div>
							<div class="col-md-4">
								<div class="form-group">
									<label class="ml-1">조회날짜(종료일)</label>
									<b-form-datepicker id="example-datepicker2" v-model="enddate" class="mb-2"></b-form-datepicker>
								</div>
							</div>  -->

						</div>
						<button type="button" class="btn btn-primary mr-2 mt-1" @click="search">조회</button>

						<img v-if="showspinner"  style="max-width:100%;" :src="require('../../../assets/images/small/spinner.gif')">
					</form>
				</div>
			</card>

			<!-- <div class="col-lg-12" v-if="showlist">
				<div class="row">
					<b-button variant="outline-success" class="mb-1 mr-2" @click="showtotal">
					총 상품수<b-badge variant="success" class="ml-2">{{productqty}}</b-badge>
					</b-button>
					<b-button variant="outline-primary" class="mb-1 mr-2" @click="showsalescnt">
					총 판매수량<b-badge variant="primary" class="ml-2">{{salescnt}}</b-badge>
					</b-button>
					<b-button variant="outline-warning" class="mb-1 mr-2" @click="showimportcnt">
					총 입고수량<b-badge variant="warning" class="ml-2">{{importcnt}}</b-badge>
					</b-button>
					<b-button variant="outline-success" class="mb-1 mr-2" @click="showsalesamount">
					총 판매매출(쿠팡)<b-badge variant="success" class="ml-2">{{salesamount}}</b-badge>
					</b-button>
					<b-button variant="outline-danger" class="mb-1 mr-2" @click="showsupplyamount">
					총 공급매출(서플라이어)<b-badge variant="danger" class="ml-2">{{supplyamount}}</b-badge>
					</b-button>
				</div>
				<div class="row">
					<b-button variant="outline-secondary" class="mb-1 mr-2" @click="shownoreview">
					리뷰 없음<b-badge variant="secondary" class="ml-2">{{noreview}}</b-badge>
					</b-button>
					<b-button variant="outline-danger" class="mb-1 mr-2" @click="shownostock">
					재고 없음<b-badge variant="danger" class="ml-2">{{nostock}}</b-badge>
					</b-button>
				</div>
			</div> -->

			<div class="col-lg-12 mt-3" v-if="showlist">
				<div class="table-responsive rounded mb-3">
<!--					<tableList>-->
<!--						<template v-slot:header>-->
<!--							<tr class="ligth ligth-data">-->
<!--								<th>상품정보</th>-->
<!--								<th v-b-tooltip.bottom="'발주가능상태가 정상인 경우에만 구매필요수량이 표시됩니다.'">발주가능상태</th>-->
<!--								<th v-b-tooltip.bottom="'발주수요예측 결과 현재 필요한 구매수량입니다.'">구매필요수량</th>-->
<!--								<th v-b-tooltip.bottom="'쿠플러스에 등록되어 있는 현재재고(국내+해외) 및 추가재고(구매+회송)를 포함한 모든 가용재고'">안전재고</th>-->
<!--								<th v-b-tooltip.bottom="'쿠팡물류 내 현재 재고수량'">쿠팡내재고</th>-->
<!--								<th v-b-tooltip.bottom="'14일간 판매수량'">출고</th>-->
<!--								<th v-b-tooltip.bottom="'제품 구매 후 입고될떄 까지의 총 걸리는 기간(일) - 상품DB에서 업데이트 가능'">입고리드타임</th>-->
<!--								<th v-b-tooltip.bottom="'평균 하루 판매수량'">일판매수량</th>-->
<!--								&lt;!&ndash; <th>일판매수량</th> &ndash;&gt;-->
<!--								&lt;!&ndash; <th>국내재고</th>-->
<!--								<th>해외재고</th>-->
<!--								<th>구매</th>-->
<!--								<th>회송</th> &ndash;&gt;-->
<!--							</tr>-->
<!--						</template>-->
<!--						<template v-slot:body>-->
<!--							<tr v-for="(saleslist,index) in saleslists" :key="index">-->
<!--								&lt;!&ndash; <td style="width:100px;overflow:hidden;text-overflow: ellipsis;">{{saleslist.date}}</td> &ndash;&gt;-->
<!--								<td v-b-modal="modalID(index)" style="min-width:100px;max-width:300px;overflow:hidden;text-overflow: ellipsis;">-->
<!--									<div class="d-flex align-items-center">-->
<!--                                        <img :src="saleslist.썸네일" class="img-fluid rounded avatar-80 mr-3" alt="image">-->
<!--                                        <div>-->
<!--                                        {{saleslist.판매상품명}}-->
<!--                                            <p class="mb-0"><small>SKU: {{saleslist.SKUID}}</small></p>-->
<!--											<p class="mb-0"><small>바코드: {{saleslist.바코드}}</small></p>-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--								</td>-->
<!--								<td v-b-modal="modalID(index)" style="width:80px;overflow:hidden;text-overflow: ellipsis;">{{saleslist.발주가능상태}}</td>-->
<!--								&lt;!&ndash; <td v-b-modal="modalID(index)" style="width:80px;overflow:hidden;text-overflow: ellipsis;">{{saleslist.일판매수량 > 0 && saleslist.발주가능상태 == '정상' ? parseInt((saleslist.안전재고+saleslist.재고)/saleslist.일판매수량-(saleslist.재고기준일+saleslist.입고리드타임)) : 0}}</td> &ndash;&gt;-->
<!--								<td v-b-modal="modalID(index)" style="width:80px;overflow:hidden;text-overflow: ellipsis;">{{saleslist.일판매수량 > 0 && saleslist.발주가능상태 == '정상' ? parseInt(parseInt((saleslist.안전재고+saleslist.재고)/saleslist.일판매수량-(saleslist.재고기준일+saleslist.입고리드타임)) > 0 ? 0 : parseInt((saleslist.재고기준일+saleslist.입고리드타임)-(saleslist.안전재고+saleslist.재고)/saleslist.일판매수량)*saleslist.일판매수량) : 0}}</td>-->
<!--								<td v-b-modal="modalID(index)" style="width:50px;overflow:hidden;text-overflow: ellipsis;">{{saleslist.안전재고}}</td>-->
<!--								<td v-b-modal="modalID(index)" style="width:50px;overflow:hidden;text-overflow: ellipsis;">{{saleslist.재고}}</td>-->
<!--								<td v-b-modal="modalID(index)" style="width:50px;overflow:hidden;text-overflow: ellipsis;">{{saleslist.판매}}</td>-->
<!--								<td style="width:50px;overflow:hidden;text-overflow: ellipsis;">{{saleslist.입고리드타임}}</td>-->

<!--								&lt;!&ndash; <td style="width:50px;overflow:hidden;text-overflow: ellipsis;">-->
<!--									<div v-if="saleslist.showleadtime === true" @click="saleslist.showleadtime = !saleslist.showleadtime">-->
<!--										{{saleslist.입고리드타임}}-->
<!--									</div>-->
<!--									<div v-else>-->
<!--										<div class="form-group">-->
<!--											<input type="number" :min="0" class="form-control" placeholder="입고리드타임 입력" v-model="saleslist.입고리드타임" @change="changeleadtime(saleslist)" @keyup.enter="saleslist.showleadtime = true">-->
<!--										</div>-->
<!--									</div>-->
<!--								</td> &ndash;&gt;-->
<!--								&lt;!&ndash; <td v-b-modal="modalID(index)" style="width:50px;overflow:hidden;text-overflow: ellipsis;">{{saleslist.재고기준일}}</td> &ndash;&gt;-->
<!--								<td v-b-modal="modalID(index)" style="width:50px;overflow:hidden;text-overflow: ellipsis;">{{saleslist.일판매수량.toFixed(2)}}</td>-->
<!--								&lt;!&ndash; <td v-b-modal="modalID(index)" style="width:50px;overflow:hidden;text-overflow: ellipsis;">{{saleslist.국내재고}}</td>-->
<!--								<td v-b-modal="modalID(index)" style="width:50px;overflow:hidden;text-overflow: ellipsis;">{{saleslist.해외재고}}</td>-->
<!--								<td v-b-modal="modalID(index)" style="width:50px;overflow:hidden;text-overflow: ellipsis;">{{saleslist.구매}}</td>-->
<!--								<td v-b-modal="modalID(index)" style="width:50px;overflow:hidden;text-overflow: ellipsis;">{{saleslist.회송}}</td> &ndash;&gt;-->

<!--									<b-modal :id="'modal'+index" scrollable title="판매세부내역" ok-title="OK" cancel-title="Close" class="detail-modal">-->
<!--										<div class="row">-->
<!--											<div class="col-12">-->
<!--												<card style="padding:10px;">-->
<!--													<div class="d-flex align-items-center" style="margin:auto;padding:0px">-->
<!--														<img :src="saleslist.썸네일" class="img-fluid rounded avatar-80 mr-3" alt="image">-->
<!--														<div>-->
<!--														판매상품명 : {{saleslist.판매상품명}}-->
<!--															<p class="mb-0"><small>SKU: {{saleslist.SKUID}}</small></p>-->
<!--															<p class="mb-0"><small>바코드: {{saleslist.바코드}}</small></p>-->
<!--														</div>-->
<!--													</div>-->
<!--												</card>-->
<!--											</div>-->
<!--											<div class="col-12">-->
<!--												<div class="table-responsive">-->
<!--													<table id="datatable" class="table data-table table-striped dataTable text-center" >-->
<!--														<thead>-->
<!--															<tr class="ligth">-->
<!--																<th>Date</th>-->
<!--																<th>발주가능상태</th>-->
<!--																<th>쿠팡내재고</th>-->
<!--																<th>판매</th>-->
<!--																<th>입고</th>-->
<!--																<th>판매가</th>-->
<!--																<th>판매매출</th>-->
<!--																<th>공급매출</th>-->
<!--																<th>리뷰수</th>-->
<!--																<th>리뷰평점</th>-->
<!--																<th>판매링크</th>-->
<!--															</tr>-->
<!--														</thead>-->
<!--														<tbody>-->
<!--															<tr v-for="(list,idx) in saleslist.판매세부내역" :key="idx">-->
<!--																<td style="width:100px;overflow:hidden;text-overflow: ellipsis;">{{list.date}}</td>-->
<!--																<td style="width:80px;overflow:hidden;text-overflow: ellipsis;">{{list.발주가능상태}}</td>-->
<!--																<td style="width:50px;overflow:hidden;text-overflow: ellipsis;">{{list.재고}}</td>-->
<!--																<td style="width:50px;overflow:hidden;text-overflow: ellipsis;">{{list.판매}}</td>-->
<!--																<td style="width:50px;overflow:hidden;text-overflow: ellipsis;">{{list.입고}}</td>-->
<!--																<td style="width:50px;overflow:hidden;text-overflow: ellipsis;">{{list.판매가}}</td>-->
<!--																<td style="width:50px;overflow:hidden;text-overflow: ellipsis;">{{list.판매매출}}</td>-->
<!--																<td style="width:50px;overflow:hidden;text-overflow: ellipsis;">{{list.공급매출}}</td>-->
<!--																<td style="width:50px;overflow:hidden;text-overflow: ellipsis;">{{list.리뷰수}}</td>-->
<!--																<td style="width:50px;overflow:hidden;text-overflow: ellipsis;">{{list.리뷰평점}}</td>-->
<!--																<td style="width:50px;overflow:hidden;text-overflow: ellipsis;" class="text-center"><a :href="saleslist.판매링크" target="_blank"><b-icon icon="link" scale="2" variant="primary"></b-icon></a></td>-->
<!--															</tr>-->
<!--														</tbody>-->
<!--													</table>-->
<!--												</div>-->
<!--											</div>-->
<!--										</div>-->
<!--                                    </b-modal>-->

<!--							</tr>-->
<!--						</template>-->
<!--					</tableList>-->
                    <CDataTable :headers="headers" :items="saleslists">
                        <template v-slot:판매상품명="{item}">
                            <div style="display: flex; align-items: center; padding: 10px 0px; line-height: 2" v-b-modal="modalID(item.SKUID)">
                                <div>
                                    <img :src="item.썸네일" class="img-fluid rounded avatar-80 mr-3" alt="image">
                                </div>
                                <div>
                                    <div style="color: #1da1f2; text-decoration: underline">{{item.판매상품명}}</div>
                                    <p class="mb-0" style="color: #676E8A;"><small>{{item.SKUID}}</small></p>
                                    <p class="mb-0" style="color: #676E8A;"><small>{{item.바코드}}</small></p>
                                </div>
                            </div>
                            <b-modal :id="'modal'+ item.SKUID" scrollable title="판매세부내역" ok-title="OK" cancel-title="Close" class="detail-modal">
                                <div class="row">
                                    <div class="col-12">
                                        <card style="padding:10px;">
                                            <div class="d-flex align-items-center" style="margin:auto;padding:0px">
                                                <img :src="item.썸네일" class="img-fluid rounded avatar-80 mr-3" alt="image">
                                                <div>
                                                    판매상품명 : {{item.판매상품명}}
                                                    <p class="mb-0"><small>SKU: {{item.SKUID}}</small></p>
                                                    <p class="mb-0"><small>바코드: {{item.바코드}}</small></p>
                                                </div>
                                            </div>
                                        </card>
                                    </div>
                                    <div class="col-12">
                                        <div class="table-responsive">
                                            <CDataTable :headers="innerHeader" :items="item.판매세부내역">
                                                <template v-slot:판매링크="{item}">
                                                    <a :href="item.판매링크" target="_blank"><b-icon icon="link" scale="2" variant="primary"></b-icon></a>
                                                </template>
                                            </CDataTable>
                                        </div>
                                    </div>
                                </div>
                            </b-modal>
                        </template>
                        <template v-slot:재고기준일="{item}">
                            {{ item.일판매수량 > 0 && item.발주가능상태 == '정상' ? parseInt(parseInt((item.안전재고+item.재고)/item.일판매수량-(item.재고기준일+item.입고리드타임)) > 0 ? 0 : parseInt((item.재고기준일+item.입고리드타임)-(item.안전재고+item.재고)/item.일판매수량)*item.일판매수량) : 0 }}
                        </template>
                        <template v-slot:일판매수량="{item}">
                            {{ item.일판매수량.toFixed(2)}}
                        </template>
                    </CDataTable>
				</div>
            <button type="button" class="btn btn-primary mr-2 mb-4" @click="downloadsales">다운로드</button>
			<!-- <button type="button" class="btn btn-success mr-2 mb-4" @click="salesdownload">일자별 판매내역 다운로드</button> -->
			</div>
		</div>
		<!-- Page end  -->
	</div>
</template>
<script>
import axios from 'axios';
// import { splice } from 'core-js/internals/enum-bug-keys';
import * as XLSX from 'xlsx';
import CDataTable from "@/components/common/CDataTable";
export default {
	name:'salesstatus',
    components:{
        CDataTable,
    },
	data(){
		return{
            headers: [
                {text: '상품정보', value: '판매상품명', align: 'center', width: 400, isSlot: true},
                {text: '발주가능상태', value: '발주가능상태', align: 'center', width: 100, isSlot: false},
                {text: '구매필요수량', value: '재고기준일', align: 'center', width: 100, isSlot: true},
                {text: '안전재고', value: '안전재고', align: 'center', width: 100, isSlot: false},
                {text: '쿠팡내재고', value: '재고', align: 'center', width: 100, isSlot: false},
                {text: '출고', value: '판매', align: 'center', width: 80, isSlot: false},
                {text: '입고리드타임', value: '입고리드타임', align: 'center', width: 100, isSlot: false},
                {text: '일판매수량', value: '일판매수량', align: 'center', width: 100, isSlot: true},
            ],
            innerHeader: [
                {text: 'Date', value: 'date', align: 'center', width: 100, isSlot: false},
                {text: '발주가능상태', value: '발주가능상태', align: 'center', width: 100, isSlot: false},
                {text: '쿠팡내재고', value: '쿠팡내재고', align: 'center', width: 100, isSlot: false},
                {text: '판매', value: '판매', align: 'center', width: 100, isSlot: false},
                {text: '입고', value: '입고', align: 'center', width: 100, isSlot: false},
                {text: '판매가', value: '판매가', align: 'center', width: 100, isSlot: false},
                {text: '판매매출', value: '판매매출', align: 'center', width: 100, isSlot: false},
                {text: '공급매출', value: '공급매출', align: 'center', width: 100, isSlot: false},
                {text: '리뷰수', value: '리뷰수', align: 'center', width: 100, isSlot: false},
                {text: '리뷰평점', value: '리뷰평점', align: 'center', width: 100, isSlot: false},
                {text: '판매링크', value: '판매링크', align: 'center', width: 100, isSlot: true}
            ],
			stddates:14,
			stdstockdates:30,
			importleadtimes:14,

			salesarr:[],
			importarr:[],
			stockarr:[],

			salescnt:0,
			importcnt:0,
			salesamount:0,
			supplyamount:0,
			noreview:0,
			productqty:0,
			nostock:0,
			// increview:0,
			// decreview:0,
            imageUrl: null,
            imageFile: null,
            extendLeft: 0,
            extendRight: 0,
            extendUp: 0,
            extendDown: 0,
            apiResponse: null,
            outputImageUrl: null,
            isDragging: false,
            startX: 0,
            startY: 0,
            endX: 0,
            endY: 0,
            maskImageURL: '',
            maskFile: '',
            isDrawing: false,
            lastX: 0,
            lastY: 0,
            selectedRegion: null,
            textPrompt: '',
            apiKey: '1fb1c6bfb2745669796d6a1ea54b0c5f931e78f1bfc5d19e57f219a55de7d388f9c466495c3a2fe9d0ab5b52c0976783',
            targetWidth: 0,
            targetHeight: 0,


			title:"AI 발주수요예측",
			desc1:"Deep Learning AI 수요예측 모델을 활용하여 발주를 대비한 안전 재고를 사전에 확보할 수 있습니다.",
			desc2:"최소 14일 이상 판매현황을 수집한 후부터 발주수요예측 기능을 사용해 주세요. 본 데이터는 실제 발주량과 다를 수 있으니 참고용으로만 사용해 주세요.",
			desc3:"입고리드타임은 상품DB에서 업데이트할 수 있으며, 업데이트 후 발주수요예측 수량이 변경될 수 있습니다.",
			desc4:"*안전재고 : 쿠팡에 아직 납품하지 않은 국내+해외+구매+회송 재고를 모두 포함한 재고수량.",
			link:{ name: 'people.addusers'},
			linktext:"판매내역수집방법",

			SKUID:'',
			showspinner: false,
			startdate: this.getTodaywith(),
			enddate: this.getTodaywith(),
			saleslists: [],
			saleslistsinit: [],
			showlist: false,


			UserListS:[
				{
					name:'Cliff Hanger',
					email:'cliff@gmail.com',
					company:'Product Manager',
					group:'Cliff',
					status:'Active',
					store:'1'
				},
				{
					name:'Terry Aki',
					email:'terry@gmail.com',
					company:'Stock CEO',
					group:'Terry',
					status:'Active',
					store:'2'
				}
			]
		}
	},
	methods: {
		changeleadtime(saleslist){
			var obj = {
				바코드 : saleslist.바코드,
				입고리드타임 : saleslist.입고리드타임
			}
			if(this.$store.state.user.companyid){
				axios.post('/api/ai/updateleadtime',{companyid:this.$store.state.user.companyid, 바코드:obj.바코드, 입고리드타임:obj.입고리드타임})
				.then(result => {
					console.log(result);
					if(result.data.요청결과 == 'ok'){
						this.$store.commit('updateleadtime',obj)
					} else {
						alert('저장실패(276)');console.log(result)
					}
				})
				.catch(e => {alert('저장실패(279)');console.log(e)})
			}
		},
		clickleadtime(saleslist){
			if(saleslist.showleadtime){
				saleslist.showleadtime = false;
			}
		},
		shownostock(){
			this.showlist = false;
			this.saleslists = this.saleslistsinit.filter(e => e.재고 == 0);
			setTimeout(()=>{this.showlist = true;},10)
		},
		showtotal(){
			this.showlist = false;
			this.saleslists = this.saleslistsinit;
			setTimeout(()=>{this.showlist = true;},10)
		},
		showsupplyamount(){
			this.showlist = false;
			this.saleslists = this.saleslistsinit.filter(e => e.공급매출 > 0)
			setTimeout(()=>{this.showlist = true;},10)
		},
		showsalesamount(){
			this.showlist = false;
			this.saleslists = this.saleslistsinit.filter(e => e.판매매출 > 0)
			setTimeout(()=>{this.showlist = true;},10)
		},
		showimportcnt(){
			this.showlist = false;
			this.saleslists = this.saleslistsinit.filter(e => e.입고 > 0)
			setTimeout(()=>{this.showlist = true;},10)
		},
		showsalescnt(){
			this.showlist = false;
			this.saleslists = this.saleslistsinit.filter(e => e.판매 > 0)
			setTimeout(()=>{this.showlist = true;},10)
		},
		shownoreview(){
			this.showlist = false;
			this.saleslists = this.saleslistsinit.filter(e => e.리뷰수 == 0)
			setTimeout(()=>{this.showlist = true;},10)
		},
		modalID(index) {
            // console.log(index);
            return 'modal' + index
        },
		salesdownload(){
			if(this.salesarr.length == 0){
				alert('다운로드할 내역이 없습니다.')
				return
			}
			for(var i=0;i<this.salesarr.length;i++){
				// console.log(i)
				this.salesarr[i]['총합계'] = 0;
				this.stockarr[i]['총합계'] = 0;
				this.importarr[i]['총합계'] = 0;
				Object.keys(this.salesarr[i]).forEach(e => {
					if(e !== 'SKUID' && e !== '바코드' && e !== '판매상품명' && e !== '총합계'){
						this.salesarr[i].총합계 += parseInt(this.salesarr[i][e]);
						this.stockarr[i].총합계 += parseInt(this.salesarr[i][e]);
						this.importarr[i].총합계 += parseInt(this.salesarr[i][e]);
					}
				})
			}
			this.salesarr.sort(function(a,b){
				if (a.총합계 < b.총합계){
					return 1;
				}
				if (a.총합계 > b.총합계){
					return -1;
				}
				return 0;
			})
			this.stockarr.sort(function(a,b){
				if (a.총합계 < b.총합계){
					return 1;
				}
				if (a.총합계 > b.총합계){
					return -1;
				}
				return 0;
			})
			this.importarr.sort(function(a,b){
				if (a.총합계 < b.총합계){
					return 1;
				}
				if (a.총합계 > b.총합계){
					return -1;
				}
				return 0;
			})

			this.stockarr.forEach(e => {
				delete e.총합계
			})
			this.importarr.forEach(e => {
				delete e.총합계
			})
			const workBook = XLSX.utils.book_new()
			var myHeader = Object.keys(this.salesarr[0]);
			var myHeader2 = myHeader.filter(e => e !== '총합계');
			const workSheet1 = XLSX.utils.json_to_sheet(this.salesarr, {header : myHeader})
			XLSX.utils.book_append_sheet(workBook, workSheet1, '판매현황')
			const workSheet2 = XLSX.utils.json_to_sheet(this.stockarr, {header : myHeader2})
			XLSX.utils.book_append_sheet(workBook, workSheet2, '재고현황')
			const workSheet3 = XLSX.utils.json_to_sheet(this.importarr, {header : myHeader2})
			XLSX.utils.book_append_sheet(workBook, workSheet3, '입고현황')
			XLSX.writeFile(workBook, '일자별판매현황' + '_' + this.startdate + '-' + this.enddate + '.xlsx')
		},

        search(){
			this.showspinner = true;
			var end = this.enddate;
			var start = this.timestamptodate(this.datetotimestamp(this.startdate) - (1000*60*60*24*this.stddates));
			axios.post('/api/listsales',{companyid:this.$store.state.user.companyid, start:start, end:end})
			.then(result => {
				// console.log(result.data.sales.length)
				// var data = JSON.parse(result.data.slice(0,-1) + "]")
				// console.log(data)
				if(result.data.sales.length == 0){
					alert('수집된 판매현황이 없습니다.')
					this.showspinner = false;
					return
				}
				this.$store.commit('salesupdate', result.data.sales);
				axios.post('/api/listsales/supplylist',{companyid:this.$store.state.user.companyid, start:start, end:end})
				.then(result2 => {
					this.$store.commit('supplyupdate', result2.data.supply);
					this.searchresult()
				})
			})
		},
		datetotimestamp(a){
			var date = new Date(a);
			return date.getTime();
		},
		searchresult(){
			this.saleslists = [];
			this.salesarr = [];
			this.saleslistsinit = [];
			this.showlist = false;
			var obj = {};
			var d = {};
			// var 이전재고 = '';
			// var t = {};
			// var su = {};
			// var 입고수량 = 0;
			var sales = [];
			this.$store.state.sales.forEach(e => {
				sales.push(this.deepClone(e))
			})
			var supply = [];
			this.$store.state.supply.forEach(e => {
				supply.push(this.deepClone(e))
			})
			var productdb = this.$store.state.productdb.filter(e => e.등록이미지 && !e.등록이미지.includes('vendor_inventory'));
			if(this.$store.state.sales.length > 0){
				console.log(this.$store.state.sales)
				console.log(this.$store.state.supply)
				var dates = [];
				sales.forEach(e => {
					if(!dates.find(ele => ele.date == e.date)){
						dates.push({date : e.date})
					}
				})
				supply.forEach(e => {
					if(!dates.find(ele => ele.date == e.importdate) && dates[0].date < e.importdate){
						dates.push({date : e.importdate})
					}
				})
				// console.log(dates)
				dates.sort(function(a,b){
					if (a.date > b.date){
						return 1;
					}
					if (a.date < b.date){
						return -1;
					}
					return 0;
				})
				productdb.sort(function(a,b){
					if (a["SKU ID"] > b["SKU ID"]){
						return 1;
					}
					if (a["SKU ID"] < b["SKU ID"]){
						return -1;
					}
					return 0;
				})
				sales.forEach(e => {
					e.판매세부내역.sort(function(a,b){
						if (a.sk > b.sk){
							return 1;
						}
						if (a.sk < b.sk){
							return -1;
						}
						return 0;
					})
				})
				supply.forEach(e => {
					e.importlist.sort(function(a,b){
						if (a.sku번호 > b.sku번호){
							return 1;
						}
						if (a.sku번호 < b.sku번호){
							return -1;
						}
						return 0;
					})
				})



				for(var i=0;i<productdb.length;i++){
					var pred = {
						재고 : 0,
						입고 : 0,
						리뷰수 : 0,
						리뷰평점 : 0,
						판매가 : 0,
					}
					obj = {
						썸네일 : productdb[i].썸네일,
						판매상품명 : productdb[i].판매상품명,
						SKUID : productdb[i]["SKU ID"],
						바코드 : productdb[i].바코드,
						발주가능상태 : productdb[i].발주가능상태,
						재고 : 0,
						판매 : 0,
						입고 : 0,
						판매가 : 0,
						판매매출 : 0,
						공급매출 : 0,
						리뷰수 : 0,
						리뷰평점 : 0,
						판매링크 : productdb[i].판매링크,
						판매세부내역 : []
					}
					var obsales = {
						판매상품명 : productdb[i].판매상품명,
						SKUID : productdb[i]["SKU ID"],
						바코드 : productdb[i].바코드
					}
					var obstock = {
						판매상품명 : productdb[i].판매상품명,
						SKUID : productdb[i]["SKU ID"],
						바코드 : productdb[i].바코드
					}
					var obimport = {
						판매상품명 : productdb[i].판매상품명,
						SKUID : productdb[i]["SKU ID"],
						바코드 : productdb[i].바코드
					}
					dates.forEach((e,k) => {

						d = {
							date : e.date,
							발주가능상태 : productdb[i].발주가능상태,
							재고 : 0,
							판매 : 0,
							입고 : 0,
							판매가 : 0,
							판매매출 : 0,
							공급매출 : 0,
							리뷰수 : 0,
							리뷰평점 : 0,
							판매링크 : productdb[i].판매링크,
							재고확인시간 : 0,
						}
						// console.log(e)
						// console.log(sales.findIndex(ele => ele.date == e.date))
						if(sales.findIndex(ele => ele.date == e.date) >= 0){
							var j = sales.findIndex(ele => ele.date == e.date)
							// console.log(j)
							for(var n=0;n<sales[j].판매세부내역.length;n++){

								if(parseInt(sales[j].판매세부내역[n].sk) == parseInt(productdb[i]["SKU ID"])){
									if(productdb[i]["SKU ID"] === 17064982 || productdb[i]["SKU ID"] ===  27573440){
										console.log(e)
										console.log(sales[j].판매세부내역[n])
									}
									d.재고 = sales[j].판매세부내역[n].s === 0 || sales[j].판매세부내역[n].s > 0 ? parseInt(sales[j].판매세부내역[n].s) : sales[j].판매세부내역[n].s < 0 ? 0 : pred.재고;
									d.리뷰수 = sales[j].판매세부내역[n].rq === 0 || sales[j].판매세부내역[n].rq ? parseInt(sales[j].판매세부내역[n].rq) : pred.리뷰수;
									d.리뷰평점 = sales[j].판매세부내역[n].rp === 0 || sales[j].판매세부내역[n].rp ? parseFloat(sales[j].판매세부내역[n].rp) : pred.리뷰평점;
									d.판매가 = sales[j].판매세부내역[n].p === 0 || sales[j].판매세부내역[n].p ? parseFloat(sales[j].판매세부내역[n].p) : pred.판매가;
									d.재고확인시간 = sales[j].time ? sales[j].time : 0;
									sales[j].판매세부내역.splice(n,1)
									// console.log(sales[j].판매세부내역.length)
									break;
								}
								if(parseInt(sales[j].판매세부내역[n].sk) > parseInt(productdb[i]["SKU ID"])){
									d.재고 = pred.재고;
									d.리뷰수 = pred.리뷰수;
									d.리뷰평점 = pred.리뷰평점;
									d.판매가 = pred.판매가;
									break;
								}
							}
						} else {
							d.재고 = pred.재고;
							d.리뷰수 = pred.리뷰수;
							d.리뷰평점 = pred.리뷰평점;
							d.판매가 = pred.판매가;
						}
						// if(productdb[i]["SKU ID"] === 18462723){
						// 	console.log(d.재고)
						// }
						if(supply.findIndex(ele => ele.importdate == e.date) >= 0){
							j = supply.findIndex(ele => ele.importdate == e.date)
							for(n=0;n<supply[j].importlist.length;n++){
								// console.log(supply[j].importlist[n])
								// console.log(supply[j].importlist[n].sku번호)
								if(parseInt(supply[j].importlist[n].sku번호) == parseInt(productdb[i]["SKU ID"])){
									// if(productdb[i]["SKU ID"] === 17064982 || productdb[i]["SKU ID"] ===  27573440){
									// 	console.log(e)
									// 	console.log(supply[j].importlist[n])
									// 	console.log(d.재고)
									// 	console.log(d.재고확인시간)
									// 	console.log(this.datetotimestamp(supply[j].importlist[n]['입고/반출일자']))
									// 	console.log(d.재고확인시간 < this.datetotimestamp(supply[j].importlist[n]['입고/반출일자']))
									// }
									d.입고 += parseInt(supply[j].importlist[n].수량);
									if(d.재고확인시간 <= this.datetotimestamp(supply[j].importlist[n]['입고/반출일자'])){
										d.재고 += parseInt(supply[j].importlist[n].수량);
									}
									// if(productdb[i]["SKU ID"] === 18462723){
									// 	console.log(supply[j].importlist[n])
									// 	console.log(d.재고)
									// }
								}
								if(parseInt(supply[j].importlist[n].sku번호) > parseInt(productdb[i]["SKU ID"])){
									// d.입고 = pred.입고;
									// d.재고 = pred.재고;
									break;
								}
							}
						}
						if(k == 0){
							d.판매 = 0;
						} else {
							d.판매 = pred.재고 - d.재고 + d.입고;
						}
						d.판매매출 = d.판매가 * d.판매;
						d.공급매출 = productdb[i].매입가 ? productdb[i].매입가 * d.판매 : productdb[i].현재매입가 ? productdb[i].현재매입가 * d.판매 : 0;
						pred = d;
						obj.판매세부내역.push(d)

						obj.재고 = parseInt(d.재고);
						obj.판매 += d.판매;
						obj.입고 += d.입고;
						obj.판매매출 += d.판매매출;
						obj.공급매출 += d.공급매출;
						obj.판매가 = d.판매가;
						obj.리뷰수 = d.리뷰수;
						obj.리뷰평점 = d.리뷰평점;

						obsales[e.date] = d.판매;
						obimport[e.date] = d.입고;
						obstock[e.date] = d.재고;
					})
					this.salesarr.push(obsales);
					this.stockarr.push(obstock);
					this.importarr.push(obimport);

					this.saleslists.push(this.deepClone(obj))
					this.saleslistsinit.push(this.deepClone(obj))
				}


				this.salescnt = this.saleslists.reduce((pv,rv) => { return pv + rv.판매},0)
				this.importcnt = this.saleslists.reduce((pv,rv) => { return pv + rv.입고},0)
				this.salesamount = this.saleslists.reduce((pv,rv) => { return pv + rv.판매매출},0)
				this.supplyamount = this.saleslists.reduce((pv,rv) => { return pv + rv.공급매출},0)
				this.noreview = this.saleslists.filter(e => e.리뷰수 == 0).length;
				this.productqty = this.saleslists.length;
				this.nostock = this.saleslists.filter(e => e.재고 == 0).length;

				//SCM관리 내역으로 변경
				this.saleslists.forEach(e => {
					var ob = this.$store.state.productdb.find(ele => ele.바코드 == e.바코드)
					if(ob.판매구성수량){
						if(ob.판매구성수량 == '단품'){
							var 판매구성수량 = 1
						} else {
							판매구성수량 = parseInt(ob.판매구성수량);
						}
					} else {
						판매구성수량 = 1;
					}
					if(ob.입고리드타임){
						var 입고리드타임 = parseInt(ob.입고리드타임);
					} else {
						입고리드타임 = this.importleadtimes;
					}
					e.국내재고 = this.$store.state.stock.length > 0 ? this.$store.state.stock.filter(ele => ele.stockinfo == 'stock' && ele.site == 'kr' && ele.바코드 == e.바코드).length : 0;
					e.해외재고 = this.$store.state.stock.length > 0 ? this.$store.state.stock.filter(ele => ele.stockinfo == 'stock' && ele.site == 'cn' && ele.바코드 == e.바코드).length : 0;
					e.구매 = this.$store.state.purchase.length > 0 ? parseInt(this.$store.state.purchase.filter(ele => ele.배송상태 !== '입고완료' && ele.바코드 == e.바코드).reduce((pv,cv) => {return pv + parseInt(cv.주문수량)},0)/판매구성수량) : 0;
					e.회송 = this.$store.state.stock.length > 0 ? this.$store.state.stock.filter(ele => ele.stockinfo == 'Rstock').length : 0;
					e.일판매수량 = e.판매/this.stddates
					e.재고기준일 = this.stdstockdates;
					e.입고리드타임 = 입고리드타임;
					e.안전재고 = e.국내재고+e.해외재고+e.구매+e.회송;
					e.구매필요수량 = e.일판매수량 > 0 && e.발주가능상태 == '정상' ? parseInt(parseInt((e.안전재고+e.재고)/e.일판매수량-(e.재고기준일+e.입고리드타임)) > 0 ? 0 : parseInt((e.재고기준일+e.입고리드타임)-(e.안전재고+e.재고)/e.일판매수량)*e.일판매수량) : 0
					e.판매횟수 = e.판매세부내역.filter(ele => ele.판매 > 0).length;
					e.showleadtime = true;
				})
				var sale = this.saleslists.filter(e => e.구매필요수량 > 0 && e.발주가능상태 == '정상' && e.판매횟수 > 1)
				sale.sort(function(a,b){
					if (a.구매필요수량 < b.구매필요수량){
						return 1;
					}
					if (a.구매필요수량 > b.구매필요수량){
						return -1;
					}
					return 0;
				})

				this.saleslists = sale;

				if(this.saleslists.length == 0){
					alert("판매현황이 없습니다.")
					this.showspinner = false;
					return
				}
				setTimeout(() => {
					this.showlist = true;
					this.showspinner = false;
				}, 1);
			} else {
				alert("판매현황이 없습니다.")
				this.showspinner = false;
				return
			}
			console.log(this.salesarr)
		},
		searchresult2(){
			this.saleslists = [];
			this.showlist = false;
			var obj = {};
			var d = {};
			var 이전재고 = '';
			var t = {};
			var su = {};
			var 입고수량 = 0;
			if(this.$store.state.sales.length > 0){
				this.$store.state.sales.sort(function(a,b){
					if (a.date < b.date){
						return -1;
					}
					if (a.date > b.date){
						return 1;
					}
					return 0;
				})
				console.log(this.$store.state.sales)
				console.log(this.$store.state.supply)
				var dates = [];
				this.$store.state.sales.forEach(e => {
					if(!dates.find(ele => ele == e.date)){
						dates.push(e.date)
					}
				})
				this.$store.state.supply.forEach(e => {
					if(!dates.find(ele => ele == e.importdate)){
						dates.push(e.importdate)
					}
				})
				dates.sort(function(a,b){
					if (a > b){
						return 1;
					}
					if (a < b){
						return -1;
					}
					return 0;
				})
				this.$store.state.productdb.forEach((e,k) => {
					console.log(k + '/' + this.$store.state.productdb.length)
					if(this.$store.state.sales.filter(ele => ele.판매세부내역.find(element => element.sk == e["SKU ID"])).length == this.$store.state.sales.length){
						obj = {
							썸네일 : e.썸네일,
							판매상품명 : e.판매상품명,
							SKUID : e["SKU ID"],
							바코드 : e.바코드,
							발주가능상태 : e.발주가능상태,
							재고 : 0,
							판매 : 0,
							입고 : 0,
							판매가 : 0,
							판매매출 : 0,
							공급매출 : 0,
							리뷰수 : 0,
							리뷰평점 : 0,
							판매링크 : e.판매링크,
							판매세부내역 : []
						}
						var ob = {
							'SKU ID':e['SKU ID'],
							바코드 : e.바코드,
							상품명 : e.상품명,
						}

						if(this.$store.state.sales.length > 1){
							this.$store.state.sales.forEach(ele => {
								t = ele.판매세부내역.find(element => element.sk == e["SKU ID"]);
								//console.log(t)
								if(this.$store.state.supply.find(element => element.importdate == ele.date)){
									su = this.$store.state.supply.find(element => element.importdate == ele.date);
									//console.log(su)
									if(su.importlist.filter(element => element.sku번호.toString() == t.sk.toString()).length > 0){
										입고수량 = su.importlist.filter(element => element.sku번호.toString() == t.sk.toString()).reduce((pv,rv) => {return pv + parseInt(rv.수량)},0)
									} else {
										입고수량 = 0;
									}
								} else {
									입고수량 = 0
								}
								if(이전재고 == ''){
									이전재고 = t.s ? t.s : 0;
								}
								d = {
									date : ele.date,
									발주가능상태 : e.발주가능상태,
									재고 : t.s+입고수량 ? t.s+입고수량 : 0,
									판매 : 0,
									입고 : 입고수량 ? 입고수량 : 0,
									판매가 : t.p ? t.p : 0,
									판매매출 : 0,
									공급매출 : 0,
									리뷰수 : t.rq ? t.rq : 0,
									리뷰평점 : t.rp ? t.rp : 0,
									판매링크 : e.판매링크,
								}
								obj.판매세부내역.push(this.deepClone(d))
								obj.재고 = t.s ? t.s : 0;
								obj.판매가 = t.p ? t.p : 0;
								obj.리뷰수 = t.rq ? t.rq : 0;
								obj.리뷰평점 = t.rp ? t.rp : 0;
								obj.입고 += d.입고 ? d.입고 : 0;
								이전재고 = d.재고 ? d.재고 : 0;
							})
							var salesqty = 0
							ob[this.$store.state.sales[0].date] = 0;
							for (var j=1;j<obj.판매세부내역.length;j++){
								salesqty = obj.판매세부내역[j-1].재고-obj.판매세부내역[j].재고+obj.판매세부내역[j].입고 ? obj.판매세부내역[j-1].재고-obj.판매세부내역[j].재고+obj.판매세부내역[j].입고 : 0;
								obj.판매세부내역[j].판매 = salesqty ? salesqty : 0;
								obj.판매 += salesqty ? salesqty : 0;
								obj.판매세부내역[j].판매매출 = salesqty*obj.판매세부내역[j].판매가 ? salesqty*obj.판매세부내역[j].판매가 : 0;
								obj.판매매출 += salesqty*obj.판매세부내역[j].판매가 ? salesqty*obj.판매세부내역[j].판매가 : 0;
								obj.판매세부내역[j].공급매출 = salesqty*e.매입가 ? salesqty*e.매입가 : 0;
								obj.공급매출 += salesqty*e.매입가 ? salesqty*e.매입가 : 0;
								ob[obj.판매세부내역[j].date] = salesqty ? salesqty : 0;
							}
							this.salesarr.push(ob)
						} else {
							this.$store.state.sales.forEach(ele => {
								t = ele.판매세부내역.find(element => element.sk == e["SKU ID"]);
								// console.log(t)
								if(this.$store.state.supply.find(element => element.importdate == ele.date)){
									su = this.$store.state.supply.find(element => element.importdate == ele.date);
									// console.log(su)
									if(su.importlist.find(element => element.sku번호.toString() == t.sk.toString())){
										입고수량 = parseInt(su.importlist.find(element => element.sku번호.toString() == t.sk.toString()).수량);
									} else {
										입고수량 = 0;
									}
								} else {
									입고수량 = 0
								}
								d = {
									date : ele.date,
									발주가능상태 : e.발주가능상태,
									재고 : t.s ? t.s : 0,
									판매 : 0,
									입고 : 입고수량 ? 입고수량 : 0,
									판매가 : t.p ? t.p : 0,
									판매매출 : 0,
									공급매출 : 0,
									리뷰수 : t.rq ? t.rq : 0,
									리뷰평점 : t.rp ? t.rp : 0,
									판매링크 : e.판매링크,
								}
								ob[ele.date] = 0;
								this.salesarr.push(ob)
								obj.판매세부내역.push(this.deepClone(d))
								obj.재고 = t.s ? t.s : 0;
								obj.판매가 = t.p ? t.p : 0;
								obj.리뷰수 = t.rq ? t.rq : 0;
								obj.리뷰평점 = t.rp ? t.rp : 0;
								obj.판매 = 0;
								obj.입고 += d.입고 ? d.입고 : 0;
								obj.판매매출 = 0;
								obj.공급매출 = 0;
								setTimeout(() => {이전재고 = d.재고 ? d.재고 : 0},1)
							})
						}
						this.saleslists.push(this.deepClone(obj))
						this.saleslistsinit.push(this.deepClone(obj))
					}
				})

				this.salescnt = this.saleslists.reduce((pv,rv) => { return pv + rv.판매},0)
				this.importcnt = this.saleslists.reduce((pv,rv) => { return pv + rv.입고},0)
				this.salesamount = this.saleslists.reduce((pv,rv) => { return pv + rv.판매매출},0)
				this.supplyamount = this.saleslists.reduce((pv,rv) => { return pv + rv.공급매출},0)
				this.noreview = this.saleslists.filter(e => e.리뷰수 == 0).length;
				this.productqty = this.saleslists.length;
				this.nostock = this.saleslists.filter(e => e.재고 == 0).length;

				if(this.saleslists.length == 0){
					alert("판매현황이 없습니다.")
					this.showspinner = false;
					return
				}
				setTimeout(() => {
					this.showlist = true;
					this.showspinner = false;
				}, 1);
			} else {
				alert("판매현황이 없습니다.")
				this.showspinner = false;
				return
			}
			console.log(this.salesarr)
		},
		deepClone(obj) {
			if (obj === null || typeof obj !== "object") {
				return obj
			}

			const result = Array.isArray(obj) ? [] : {}

			for (let key of Object.keys(obj)) {
				result[key] = this.deepClone(obj[key])
			}

			return result
		},
		todate(date){
			var today = new Date(date);
			today.setHours(today.getHours() + 9);
			return today.toISOString().replace('T', ' ').substring(0, 19);
		},
        timestamp(date){
            //inputdate = "yyyy-mm-dd"
            return new Date(date).getTime()
        },
		getTodaywith(){
			var date = new Date();
			var year = date.getFullYear();
			var month = ("0" + (1 + date.getMonth())).slice(-2);
			var day = ("0" + date.getDate()).slice(-2);

			return year + "-" + month + "-" + day;
		},
		timestamptodate(timestamp){
			var date = new Date(timestamp);
			var year = date.getFullYear();
			var month = ("0" + (1 + date.getMonth())).slice(-2);
			var day = ("0" + date.getDate()).slice(-2);

			return year + "-" + month + "-" + day;
		},
		downloadsales() {
			function getToday(){
				var date = new Date();
				var year = date.getFullYear();
				var month = ("0" + (1 + date.getMonth())).slice(-2);
				var day = ("0" + date.getDate()).slice(-2);

				return year + month + day;
			}
			var arr = [];
			var obj = {};
			console.log(this.saleslists)
			this.saleslists.forEach(e => {
				obj = {
					SKUID : e.SKUID,
					바코드 : e.바코드,
					상품명 : e.판매상품명,
					발주가능상태 : e.발주가능상태,
					구매필요수량 : e.구매필요수량,
					안전재고 : e.안전재고,
					쿠팡내재고 : e.재고,
					출고 : e.판매,
					입고리드타임 : e.입고리드타임,
					// 재고기준일 : e.재고기준일,
					일판매수량 : e.일판매수량
				}
				arr.push(obj)
			})
			const workBook = XLSX.utils.book_new()
			const workSheet1 = XLSX.utils.json_to_sheet(arr)
			XLSX.utils.book_append_sheet(workBook, workSheet1, '발주수요예측')
			XLSX.writeFile(workBook, '발주수요예측' + '_' + getToday() + '.xlsx')
		}
	}

}
</script>

<style>
.my-class .dropdown-menu {

max-height: 300px;
width:800px;
overflow-y: auto;
}
</style>
